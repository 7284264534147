import { appConfig } from 'appConfig'

const BaseColors = {
    UiBlack1: '#14202c',
    UiBlack3: '#3b4b6a',
    UiBlack4: '#6780aa',
    UiBlack6: '#1B2435',
    UiBlack7: '#232324',

    Grey: '#69728B',
    GreyLight: '#abb4c9',
    Black: '#050a10',
    White: '#ffffff',

    Grey1: '#f7f7fa',
    Grey2: '#c6c7cb',
    Grey3: '#858891',
    Grey4: '#303d58',
    Grey5: '#3B4B6A',
    Grey6: '#B1B4BD',
    Grey7: '#677497',
    Grey8: '#838FAE',
    Grey9: '#707688',
    Grey10: '#34435F',
    Grey11: '#20293A',
    Grey12: '#9BA2B0',
    Grey13: '#8599BB',
    ActiveGrey: '#2D3952',

    TransparentWhite: '#FFFFFF44',
    Transparent: 'rgba(0,0,0,0)',
    TransparentBlack: 'rgba(0,0,0,0.6)',
    TransparentGrey: '#E5E6EA',

    Green: '#32a538',
    DarkGreen: '#0a6e2c',
    LightGreen: '#45eb4d',
    ToastGreen: '#409244',
    PaleGreen: '#a6d46d',
    VeryPaleOrange: '#F6F0E9',
    UltraPaleGreen: '#E9F6EA',
    SoftGreen: '#81c48d',
    Yellow: '#f8ba00',
    DarkYellow: '#664A02',
    PaleOrange: '#f1a267',
    Orange: '#ff6d00',
    Red: '#e42626',
    PaleRed: '#F6E9E9',
    DarkRed: '#280006',
    Magenta: '#da58ab',
    SoftMagenta: '#d958ab',
    Violet: '#6c55a7',
    SoftBlue: '#4c85ff',
    SeaBlue: '#4dc8c9',
    LightBlue: '#E9EEF6',
    PaleBlue: '#DFE9FF',
    TaskBlue: '#1A62FB',
    IntensiveBlue: '#1D62FB',
    TaskYellow: '#ffd145',
    DarkBlue: '#060B14',

    ProgressGreen: '#69F996',
    ProgressRed: '#FF073A',
    ProgressGrey: '#D9D9D9',
    ProgressYellow: '#FFB904',
    ProgressBarGradient: 'linear-gradient(180deg, rgba(108,255,156,1) 0%, rgba(50,165,56,1) 100%)',
    UnitTaskBlack: '#16181C',
    UnitTaskYellow: '#332500',
    UnitTaskGreen: '#163320',
    UnitTaskRed: '#140001',
}

const AosColors = {
    PrimaryLight: '#417eff',
    Primary: '#005de5',
    PrimaryDark: '#0f4aca',
    PrimaryPale: '#7ca6ff',
    FontColor: '#FFFFFF',
    FontLightColor: '#35427C',
    WidgetFontColor: '#FFFFFF',
    Background: '#0b1029',
    BottomTabsBackground: '#0b1029',
    LightBackground: '#182146',
    WidgetBackground: '#232d43',
    FlightsScreenAirportSelectBackground: '#005de5',
    PrimaryButtonText: '#FFF',
    ProgressBarBackground: 'rgba(24, 33, 70, 0.4)',
    ProgressBar: '#FFF',
    ReportingItem: '#182146',
    ReportingItemIcon: '#6780aa',
}

const AoimsColors = {
    PrimaryLight: '#FFE270',
    Primary: '#FFDD4F',
    PrimaryDark: '#b08f22',
    PrimaryPale: '#2D2D2D',
    FontColor: '#0F0F0F',
    FontLightColor: '#B9B9B9',
    Background: '#0F0F0F',
    BottomTabsBackground: '#2D2D2D',
    LightBackground: '#FFDD4F',
    WidgetBackground: '#2D2D2D',
    WidgetFontColor: '#B9B9B9',
    FlightsScreenAirportSelectBackground: '#2D2D2D',
    PrimaryButtonText: '#0F0F0F',
    ProgressBarBackground: '#FFF1B8',
    ProgressBar: '#1C1C1C',
    ReportingItem: '#2D2D2D',
    ReportingItemIcon: '#b08f22',
}

const ColorConfig: Record<string, typeof AosColors> = {
    AOS: AosColors,
    AOIMS: AoimsColors,
}

export const Color = {
    ...BaseColors,
    ...ColorConfig[appConfig.theme],
}

export const statusBarColor = {
    AOS: 'light-content',
    AOIMS: 'dark-content',
}[appConfig.theme]

export type Color =
    | (typeof BaseColors)[keyof typeof BaseColors]
    | (typeof AosColors)[keyof typeof AosColors]
    | (typeof AoimsColors)[keyof typeof AoimsColors]
