import { MapVariant } from './MapVariant'

export enum MapSiteLocationCustomization {
    MainAirport = 'MAIN_AIRPORT',
    Other = 'OTHER',
}

export const toMapVariant = (siteCustomization: MapSiteLocationCustomization): MapVariant =>
    siteCustomization === MapSiteLocationCustomization.Other
        ? MapVariant.World
        : MapVariant.Terminal
