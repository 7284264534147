import { Color } from 'aos-ui-common/src/styles/Color'
import styled, { css } from 'styled-components'

import { Box } from '../base/Box'

const getBackgroundColor = (
    selected?: boolean,
    lightBg?: boolean,
    checkedGreen?: boolean,
    outlined?: boolean,
): string => {
    if (outlined) {
        return Color.Transparent
    }
    if (selected) {
        return checkedGreen ? Color.Green : Color.UiBlack3
    }
    if (lightBg) {
        return Color.WidgetBackground
    }
    return Color.UiBlack1
}

const getBorderColor = (selected?: boolean, outlined?: boolean): Color | undefined => {
    if (outlined) {
        return selected ? Color.Green : Color.UiBlack3
    }
    return Color.UiBlack3
}

export const DarkListItem = styled(Box)<{
    isDragging?: boolean
    borderColor?: Color
    selected?: boolean
    lightBg?: boolean
    checkedGreen?: boolean
    outlined?: boolean
}>`
    position: relative;
    background-color: ${p => getBackgroundColor(p.selected, p.lightBg, p.checkedGreen, p.outlined)};
    border: ${p => (p.outlined ? 2 : 1)}px solid ${p => getBorderColor(p.selected, p.outlined)};

    ${p =>
        p.borderColor &&
        css`
            box-shadow: inset 4px 0 0 ${p.borderColor};
        `}

    ${p =>
        p.isDragging &&
        css`
            box-shadow: none;
            > * {
                opacity: 0;
            }
        `}
`
