import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC } from 'react'
import styled from 'styled-components'

import { BlockSize } from '../../../../aos-helpers/src/helpers/Block'
import { Box } from '../base/Box'
import { ButtonProps } from '../base/ButtonProps'
import { Text } from '../base/Text'
import { borderRadius, FormComponentSize, time } from '../base/Theme'
import { Icon } from '../svg/Icon'
import { SvgIcon } from '../svg/SvgIcon'
import { Button } from './Button'

interface MultiButtonProps extends ButtonProps {
    label: string | React.ReactNode
    onClickMore: () => void
}
export const MultiButton: FC<MultiButtonProps> = ({
    label,
    seleniumLocation,
    id,
    onClick,
    onClickMore,
    disabled,
}) => {
    return (
        <Box row columnGap={2}>
            <StyledButton
                borderRadius={`${borderRadius.default}px 0 0 ${borderRadius.default}px`}
                id={id}
                onClick={onClick}
                type='button'
                disabled={disabled}
                data-test-id={seleniumLocation || 'multi-button'}
            >
                <Text padding={12}>{label}</Text>
            </StyledButton>
            <StyledButton
                onClick={onClickMore}
                borderRadius={`0 ${borderRadius.default}px ${borderRadius.default}px 0`}
            >
                <Box row paddingVertical={12} paddingHorizontal={8}>
                    <Icon iconSize={BlockSize.Std} svg={SvgIcon.More} />
                </Box>
            </StyledButton>
        </Box>
    )
}

const StyledButton = styled(Button)<{ borderRadius: string }>`
    border-radius: ${p => p.borderRadius};
    position: relative;
    color: ${Color.PrimaryButtonText};
    transition: background-color ${time.fast}s ease;
    background-color: ${Color.Primary};
    line-height: ${FormComponentSize.Std}px;
    font-size: 14px;
    :hover {
        background-color: ${Color.PrimaryLight};
    }
    :disabled {
        background-color: ${Color.UiBlack3};
        color: ${Color.Grey2};
    }
`
