import { AosSeverity } from 'aos-services/src/services/common/types/AosSeverity'
import { TaskUpdateMode } from 'aos-services/src/services/tasks/types/payload/BaseTaskFormPayload'
import { Task } from 'aos-services/src/services/tasks/types/task/Task'
import { TaskFilter } from 'aos-services/src/services/tasks/types/TaskFilter'
import { TaskId } from 'aos-services/src/services/tasks/types/TaskSnapshot'
import { TaskStatus } from 'aos-services/src/services/tasks/types/TaskStatus'
import { Box } from 'aos-ui/src/components/base/Box'
import { PanelWithHeader } from 'aos-ui/src/components/container/PanelWithHeader'
import { ResizableSplittedPanel } from 'aos-ui/src/components/container/ResizableSplittedPanel'
import { DarkScrollbar } from 'aos-ui/src/components/scrollbar/DarkScrollbar'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC, useEffect, useState } from 'react'
import { connect } from 'react-redux'

import {
    changeFirstRecurrentTaskChecklistStateAction,
    changeRecurrentTaskChecklistStateAction,
    changeStatusAction,
    changeTaskChecklistStateAction,
    cloneTaskAction,
    deleteTaskAction,
    editTaskAction,
    loadTasksAction,
    openTaskAction,
    previewTaskLocationAction,
    saveTaskAction,
    selectTaskAction,
    sendMessageAction,
    syncTaskAction,
    syncTaskListAction,
} from '../../../core/tasks/actions'
import { taskListSelector, TaskListSelectorState } from '../../../core/tasks/selectors'
import { TaskEditModeModal } from '../createTask/TaskEditModeModal'
import { TasksDetailsRouter } from '../details/TaskDetailsRouter'
import { TasksHeader } from './TasksHeader'
import { TasksList } from './TasksList'

interface TasksListContainerProps extends TaskListSelectorState, TaskListDispatchProps {
    filter: TaskFilter
}

interface ConfirmUpdateModeState {
    keyPrefix: string
    onSubmit(v: TaskUpdateMode): void
}

const TasksListContainerComponent: FC<TasksListContainerProps> = ({
    filter,
    tasks,
    loadTasks,
    openTask,
    selectedTask,
    isLoading,
    isSendingMessage,
    selectTask,
    tasksFilters,
    editTask,
    deleteTask,
    changeStatus,
    sendMessage,
    cloneTask,
    previewLocation,
    syncList,
    syncTask,
    changeTaskChecklistItem,
    firstChangeTaskRecurrentChecklistItem,
    changeTaskRecurrentChecklistItem,
    url,
}) => {
    useEffect(() => {
        loadTasks({ ...tasksFilters, taskFilter: filter, page: 0 })
        syncList(true)
        return () => {
            syncList(false)
        }
    }, [filter])
    const [modeState, setModeState] = useState<ConfirmUpdateModeState>()

    const onEditTask = (task: Task) => {
        if (task.parentTaskId && task.status === TaskStatus.Todo) {
            setModeState({
                keyPrefix: 'tasks.confirm-edit',
                onSubmit: mode => {
                    editTask([task, mode])
                    setModeState(undefined)
                },
            })
        } else {
            editTask([task, TaskUpdateMode.Instance])
        }
    }
    const onDeleteTask = (task: TaskId) => {
        if (task.parentTaskId) {
            setModeState({
                keyPrefix: 'tasks.confirm-delete',
                onSubmit: mode => {
                    deleteTask([task, mode])
                    setModeState(undefined)
                },
            })
        } else {
            deleteTask([task, TaskUpdateMode.Instance])
        }
    }

    const onCloneTask = (task: Task) => {
        cloneTask(task)
    }

    const onCloseStatus = (taskId: TaskId) => {
        if (taskId.parentTaskId) {
            setModeState({
                keyPrefix: 'tasks.confirm-close',
                onSubmit: mode => {
                    changeStatus([
                        {
                            status: TaskStatus.Done,
                            severity: selectedTask?.severity as AosSeverity,
                        },
                        taskId,
                        mode,
                    ])
                    setModeState(undefined)
                },
            })
        } else {
            changeStatus([
                { status: TaskStatus.Done, severity: selectedTask?.severity as AosSeverity },
                taskId,
                TaskUpdateMode.Instance,
            ])
        }
    }
    const onChangeStatus = ([status, taskId, severity]: [TaskStatus, TaskId, AosSeverity]) => {
        changeStatus([{ status, severity }, taskId, TaskUpdateMode.Instance])
    }

    return (
        <PanelWithHeader
            header={
                <TasksHeader loadTasks={loadTasks} tasksFilters={tasksFilters} filter={filter} />
            }
        >
            <ResizableSplittedPanel
                fullSize
                flex={1}
                initialRightSizes={[60, 40]}
                leftMinSizes={[30]}
                rightMinSizes={[30]}
            >
                <Box fullHeight bg={Color.UiBlack1}>
                    <DarkScrollbar>
                        <TasksList
                            selectedTask={selectedTask}
                            tasks={tasks}
                            onChangePage={({ size, number }) =>
                                loadTasks({ ...tasksFilters, pageSize: size, page: number })
                            }
                            onOpen={t => openTask([filter, t])}
                        />
                    </DarkScrollbar>
                </Box>
                <Box fullHeight bg={Color.UiBlack1}>
                    <TasksDetailsRouter
                        url={url}
                        selectedTask={selectedTask}
                        isLoading={isLoading}
                        isSendingMessage={isSendingMessage}
                        selectTask={selectTask}
                        syncTask={syncTask}
                        previewLocation={previewLocation}
                        cloneTask={onCloneTask}
                        editTask={onEditTask}
                        deleteTask={onDeleteTask}
                        changeStatus={onChangeStatus}
                        closeTask={onCloseStatus}
                        sendMessage={sendMessage}
                        changeTaskChecklistItem={([taskId, checklistId, itemId, done]) =>
                            changeTaskChecklistItem([taskId, checklistId, itemId, done])
                        }
                        changeFirstTaskRecurrentChecklistItem={([
                            parentId,
                            instanceId,
                            templateId,
                            seq,
                            done,
                        ]) =>
                            firstChangeTaskRecurrentChecklistItem([
                                parentId,
                                instanceId,
                                templateId,
                                seq,
                                done,
                            ])
                        }
                        changeTaskRecurrentChecklistItem={([
                            parentId,
                            instanceId,
                            checklistId,
                            itemId,
                            done,
                        ]) =>
                            changeTaskRecurrentChecklistItem([
                                parentId,
                                instanceId,
                                checklistId,
                                itemId,
                                done,
                            ])
                        }
                    />
                </Box>
            </ResizableSplittedPanel>
            {modeState && (
                <TaskEditModeModal
                    isOpen
                    keyPrefix={modeState.keyPrefix}
                    onClose={() => setModeState(undefined)}
                    onSubmit={modeState.onSubmit}
                />
            )}
        </PanelWithHeader>
    )
}

interface TaskListDispatchProps {
    loadTasks: typeof loadTasksAction
    openTask: typeof openTaskAction
    selectTask: typeof selectTaskAction
    changeStatus: typeof changeStatusAction
    sendMessage: typeof sendMessageAction
    editTask: typeof editTaskAction
    deleteTask: typeof deleteTaskAction
    saveTask: typeof saveTaskAction
    previewLocation: typeof previewTaskLocationAction
    syncList: typeof syncTaskListAction
    syncTask: typeof syncTaskAction
    cloneTask: typeof cloneTaskAction
    changeTaskChecklistItem: typeof changeTaskChecklistStateAction
    firstChangeTaskRecurrentChecklistItem: typeof changeFirstRecurrentTaskChecklistStateAction
    changeTaskRecurrentChecklistItem: typeof changeRecurrentTaskChecklistStateAction
}

export const TasksListPreview = connect<TaskListSelectorState, TaskListDispatchProps>(
    taskListSelector,
    {
        loadTasks: loadTasksAction,
        openTask: openTaskAction,
        selectTask: selectTaskAction,
        changeStatus: changeStatusAction,
        sendMessage: sendMessageAction,
        editTask: editTaskAction,
        deleteTask: deleteTaskAction,
        saveTask: saveTaskAction,
        previewLocation: previewTaskLocationAction,
        syncList: syncTaskListAction,
        syncTask: syncTaskAction,
        cloneTask: cloneTaskAction,
        changeTaskChecklistItem: changeTaskChecklistStateAction,
        firstChangeTaskRecurrentChecklistItem: changeFirstRecurrentTaskChecklistStateAction,
        changeTaskRecurrentChecklistItem: changeRecurrentTaskChecklistStateAction,
    },
)(TasksListContainerComponent)
