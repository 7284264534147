import { cx } from 'aos-components/src/components/base/ClassNames'
import { DateTime, dateTime } from 'aos-helpers/src/helpers/Time'
import { translate, translateEnum } from 'aos-helpers/src/helpers/translations/Translations'
import {
    AdditionalPreferenceItem,
    allNotificationsPreferencesDays,
    allNotificationsPreferencesTypes,
    AosUserNotificationsPreferences,
    AosUserNotificationsTimePreference,
    defaultDisabledTimeNotificationPreferences,
    defaultEnabledTimeNotificationPreferences,
    NotificationsPreferencesDay,
    NotificationsPreferencesType,
} from 'aos-services/src/services/users/types/AosUserNotificationsPreferences'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { BlockCheckboxGroup } from 'aos-ui/src/components/form/checkbox/BlockCheckboxGroup'
import { Checkbox } from 'aos-ui/src/components/form/checkbox/Checkbox'
import { Toggle } from 'aos-ui/src/components/form/checkbox/Toggle'
import { TimeInput } from 'aos-ui/src/components/form/datetime/TimeInput'
import { LightScrollbar } from 'aos-ui/src/components/scrollbar/LightScrollbar'
import { Info } from 'aos-ui/src/components/ui/Info'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { PureComponent } from 'react'

interface NotificationSettingsFormProps {
    settings: AosUserNotificationsPreferences
    onUpdate: (settings: Partial<AosUserNotificationsPreferences>) => void
}

export class NotificationSettingsForm extends PureComponent<NotificationSettingsFormProps> {
    private translateType = translateEnum<NotificationsPreferencesType>(
        NotificationsPreferencesType,
        'notification-settings.type',
    )

    private translateDay = translateEnum<NotificationsPreferencesDay>(
        NotificationsPreferencesDay,
        'notification-settings.day',
    )

    private optionOn: AdditionalPreferenceItem = { push: true, email: true }
    private optionOff: AdditionalPreferenceItem = { push: false, email: false }

    public render() {
        return (
            <Box flex={1}>
                <Box className='flex-grid' fullHeight alignItems='stretch'>
                    <Box className='col-6'>{this.renderLeftColumn()}</Box>
                    <Box className='col-6' bg={Color.Grey1} padding={30}>
                        {this.renderRightColumn()}
                    </Box>
                </Box>
            </Box>
        )
    }

    private renderLeftColumn() {
        return (
            <Box fullHeight flex={1}>
                <LightScrollbar autoHeightMin='100%' autoHeightMax='100%'>
                    <Box padding={30}>
                        <h3 className='text__xxl-black'>
                            {translate('notification-settings.set-additional')}
                        </h3>
                        <p className='text__m-ow-pale text--lh-std padding-vertical--std'>
                            {translate('notification-settings.set-additional.info')}
                        </p>
                        <Box
                            className={cx(
                                'flex-grid border-bottom--grey',
                                'padding-bottom--smallest margin-bottom--small',
                                'text__s-black text--bold  text--center',
                            )}
                        >
                            <Box className='col-8' />
                            <Box className='col-2'>
                                {translate('notification-settings.push-or-email.push')}
                            </Box>
                            <Box className='col-2'>
                                {translate('notification-settings.push-or-email.email')}
                            </Box>
                        </Box>
                        {allNotificationsPreferencesTypes.map(this.renderChecks)}
                    </Box>
                </LightScrollbar>
            </Box>
        )
    }

    private renderChecks = (type: NotificationsPreferencesType) => {
        const value = this.getOption(type)

        const togglePush = () => this.updateOption(type, { push: !value.push })
        const toggleEmail = () => this.updateOption(type, { email: !value.email })
        const toggleAll = () => this.updateOption(type, value.push ? this.optionOff : this.optionOn)

        return (
            <Box className='flex-grid' paddingVertical={8} key={type}>
                <Text
                    className='col-8 pointer'
                    color={Color.WidgetBackground}
                    size={12}
                    onClick={toggleAll}
                >
                    {this.translateType(type)}
                </Text>
                <Box className='col-2' centered>
                    <Checkbox checked={value.push} onChange={togglePush} />
                </Box>
                <Box className='col-2' centered>
                    <Checkbox checked={value.email} onChange={toggleEmail} />
                </Box>
            </Box>
        )
    }

    private getOption(type: NotificationsPreferencesType): AdditionalPreferenceItem {
        const { additional } = this.props.settings
        return additional[type]
    }

    private updateOption(
        type: NotificationsPreferencesType,
        data: Partial<AdditionalPreferenceItem>,
    ) {
        const { onUpdate, settings } = this.props

        const newAdditional = {
            ...settings.additional,
            [type]: { ...this.getOption(type), ...data },
        }

        onUpdate({ additional: newAdditional })
    }

    private renderRightColumn() {
        const { timePreference } = this.props.settings
        const timePickerEnabled = !!(timePreference.fromTime || timePreference.toTime)

        return (
            <>
                <Box className='flex-grid'>
                    <Box className='col-10'>
                        <h3 className='text__xxl-black'>
                            {translate('notification-settings.set-time')}
                        </h3>
                    </Box>
                    <Box className='col-2' justify='flex-end'>
                        <Toggle value={timePickerEnabled} onChange={this.handleToggleTimePicker} />
                    </Box>
                </Box>
                <p className='text__m-ow-pale text--lh-std padding-vertical--std'>
                    {translate('notification-settings.set-time.info')}
                </p>
                {timePickerEnabled && this.renderTimePicker()}
            </>
        )
    }

    private renderTimePicker() {
        const { timePreference } = this.props.settings
        return (
            <Box>
                <Box className='flex-grid' marginTop={16}>
                    <Box className='col-4' marginRight={20}>
                        <Text paddingBottom={8} color={Color.UiBlack1} size={12} weight='bold'>
                            {translate('notification-settings.from')}
                        </Text>
                        <TimeInput
                            value={dateTime(timePreference.fromTime!, 'HH:mm')}
                            onChange={date => this.changeTime('fromTime', date)}
                            hideClear
                        />
                    </Box>
                    <Box className='col-4'>
                        <Text paddingBottom={8} color={Color.UiBlack1} size={12} weight='bold'>
                            {translate('notification-settings.to')}
                        </Text>
                        <TimeInput
                            value={dateTime(timePreference.toTime!, 'HH:mm')}
                            onChange={(date: DateTime) => this.changeTime('toTime', date)}
                            hideClear
                        />
                    </Box>
                </Box>
                <Box paddingVertical={20}>
                    <BlockCheckboxGroup
                        items={allNotificationsPreferencesDays}
                        values={timePreference.days}
                        onChange={this.handleChangeDay}
                        renderer={item => this.translateDay(item)}
                    />
                </Box>
                <Info>{translate('notification-settings.additional.note')}</Info>
            </Box>
        )
    }

    private handleChangeDay = (days: NotificationsPreferencesDay[]) => {
        this.updateTimePreference('days', days)
    }

    private changeTime(type: keyof AosUserNotificationsTimePreference, date?: DateTime) {
        if (date) {
            const time = date.clone().format('HH:mm')
            this.updateTimePreference(type, time)
        }
    }

    private updateTimePreference(
        type: keyof AosUserNotificationsTimePreference,
        value: NotificationsPreferencesDay[] | string | null,
    ) {
        const { timePreference } = this.props.settings
        this.props.onUpdate({ timePreference: { ...timePreference, [type]: value } })
    }

    private handleToggleTimePicker = (value: boolean) => {
        this.props.onUpdate({
            timePreference: value
                ? defaultEnabledTimeNotificationPreferences
                : defaultDisabledTimeNotificationPreferences,
        })
    }
}
