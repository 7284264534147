import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { translate } from 'aos-helpers/src/helpers/translations/Translations'
import {
    currentUserSelector,
    currentUserSiteLocation,
    displayNameSelector,
    userThumbnailSelector,
} from 'aos-services/src/core/auth/state'
import { useKeycloak } from 'aos-services/src/providers/keycloak'
import { locationName } from 'aos-services/src/services/flightInformation/types/AosAirport'
import { Box } from 'aos-ui/src/components/base/Box'
import { Text } from 'aos-ui/src/components/base/Text'
import { BurgerButton } from 'aos-ui/src/components/buttons/BurgerButton'
import { MultiButton } from 'aos-ui/src/components/buttons/MultiButton'
import { More, MoreIconItem } from 'aos-ui/src/components/list/More'
import { Icon } from 'aos-ui/src/components/svg/Icon'
import { SvgIcon } from 'aos-ui/src/components/svg/SvgIcon'
import { UserPhoto } from 'aos-ui/src/components/UserPhoto'
import { Color } from 'aos-ui-common/src/styles/Color'
import { appConfig } from 'appConfig'
import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { Action } from 'redux'
import { replace } from 'redux-first-history'
import styled from 'styled-components'

import { SyncWrapper } from '../../components/sync/SyncWrapper'
import {
    createEventShowModalAction,
    reportSimpleFeedInShowModalAction,
} from '../../core/eventModals/actions'
import { toggleMenuAction } from '../../core/layout/actions'
import { isMenuOpenSelector } from '../../core/layout/state'
import { Link as LinkConfig } from '../../core/Links'
import {
    openNotificationsContainerAction,
    syncNotificationsAction,
} from '../../core/notifications/actions'
import { getUnreadNotificationCountSelector } from '../../core/notifications/state'
import { openReportingModalAction } from '../../core/reporting/actions'
import { setTaskCreateModalOpenAction } from '../../core/tasks/actions'
import { toggleUserProfileModalAction } from '../../core/userProfileModal/actions'
import { Logo } from './Logo'
import { NotificationBell } from './NotificationBell'

export const AppHeader = React.memo(() => {
    const dispatch = useDispatch()
    const location = useSelector(currentUserSiteLocation)
    const userName = useSelector(displayNameSelector)
    const thumbnail = useSelector(userThumbnailSelector)
    const bellCount = useSelector(getUnreadNotificationCountSelector)
    const isMenuOpen = useSelector(isMenuOpenSelector)
    const currentUser = useSelector(currentUserSelector)
    const siteLocation = useLocation()
    const toggleMenu = () => dispatch(toggleMenuAction())
    const { keycloak } = useKeycloak()

    const renderNotificationBell = useMemo(() => {
        return (
            <SyncWrapper onEnter={syncNotificationsAction()}>
                <Box centered paddingHorizontal={30}>
                    <NotificationBell
                        count={bellCount}
                        onClick={() => dispatch(openNotificationsContainerAction())}
                    />
                </Box>
            </SyncWrapper>
        )
    }, [bellCount])

    const buttonProps = useMemo(() => {
        let labelKey = 'create-task.header-button'
        let icon = SvgIcon.Assignment
        let onClickAction: () => Action = () => dispatch(setTaskCreateModalOpenAction(true))

        const canEditTasks = currentUser.canEditTasks()
        const isEventPage = siteLocation.pathname.includes('event')
        const isTaskPage = siteLocation.pathname.includes('task')
        const isApoc = currentUser.isApoc()
        const isHel = currentUser.isMainAirport()

        if (isEventPage) {
            labelKey = isApoc ? 'activate-event.header-button' : 'report-feed-in.header-button'
            onClickAction = isApoc
                ? () => dispatch(createEventShowModalAction())
                : () => dispatch(reportSimpleFeedInShowModalAction())
            icon = SvgIcon.Events
        } else if (isTaskPage && canEditTasks) {
            onClickAction = () => dispatch(setTaskCreateModalOpenAction(true))
            icon = SvgIcon.Assignment
        } else if (isApoc && (isHel || !canEditTasks)) {
            labelKey = 'activate-event.header-button'
            onClickAction = () => dispatch(createEventShowModalAction())
            icon = SvgIcon.Events
        } else if (isHel) {
            labelKey = 'report-feed-in.header-button'
            onClickAction = () => dispatch(reportSimpleFeedInShowModalAction())
            icon = SvgIcon.Events
        } else if (!canEditTasks) {
            labelKey = 'report-feed-in.header-button'
            onClickAction = () => dispatch(reportSimpleFeedInShowModalAction())
            icon = SvgIcon.Events
        }

        return {
            label: translate(labelKey),
            icon,
            onClick: onClickAction,
        }
    }, [currentUser, siteLocation, dispatch])

    const renderCreateEventButton = useMemo(() => {
        return (
            <Box columnGap={12}>
                <MultiButton
                    onClickMore={() => dispatch(openReportingModalAction())}
                    onClick={buttonProps.onClick}
                    id='create-task-or-event'
                    label={
                        <Box columnGap={4} row>
                            <Icon iconSize={BlockSize.Std} svg={buttonProps.icon} />
                            <Text>{buttonProps.label}</Text>
                        </Box>
                    }
                />
            </Box>
        )
    }, [currentUser, buttonProps, dispatch])

    const handlePrivacyPolicy = () => {
        const url = `${appConfig.origin}/policy.html`
        window.open(url)
    }

    const handleUserProfile = () => {
        dispatch(toggleUserProfileModalAction(true))
    }

    const renderUserSection = useMemo(() => {
        const trigger = (
            <Box as='button' row>
                <UserPhoto base64Img={thumbnail} />
            </Box>
        )

        const header = (
            <Text size={14} color={Color.WidgetBackground} weight='bold'>
                {userName}
            </Text>
        )
        return (
            <More trigger={trigger} header={header}>
                <MoreIconItem icon={SvgIcon.Description} onClick={handlePrivacyPolicy}>
                    {translate('header.user.privacy-policy')}
                </MoreIconItem>
                <MoreIconItem icon={SvgIcon.Settings} onClick={handleUserProfile}>
                    {translate('header.user.settings')}
                </MoreIconItem>
                <MoreIconItem
                    icon={SvgIcon.Delete}
                    onClick={() => dispatch(replace(LinkConfig.RemoveAccount))}
                >
                    {translate('header.user.delete-account')}
                </MoreIconItem>
                <MoreIconItem icon={SvgIcon.LogOut} onClick={keycloak?.logout}>
                    {translate('header.user.logout')}
                </MoreIconItem>
            </More>
        )
    }, [thumbnail, userName])

    return (
        <Box as='header' row>
            <BurgerButton onClick={toggleMenu} isExpanded={isMenuOpen} />
            <Box row flex={1} paddingRight={20}>
                <Text as='h1' row flex={1} textNoWrap color={Color.White} size={22}>
                    <NavLink to='/'>
                        <Logo
                            aria-label={translate('header.title')}
                            location={locationName(location)}
                        />
                    </NavLink>
                </Text>
                {renderCreateEventButton}
                {renderNotificationBell}
                {renderUserSection}
            </Box>
        </Box>
    )
})

const NavLink = styled(Link)`
    cursor: pointer;
    width: 100%;
`
