import { reduce } from 'lodash'
import { transformExtent } from 'ol/proj'

import { AosAirport, typedAirports } from '../flightInformation/types/AosAirport'
import { MapPosition } from './types/MapPosition'

export const selectedLocationZoom = 14

export const helsinkiAirportPosition = {
    lon: 24.967433400000004,
    lat: 60.31615160624082,
    zoom: 16.3,
}

export const helsinkiAtcCenterPosition = {
    lon: 24.948,
    lat: 60.32,
    zoom: 13.5,
}

export const atcGridPositionExtent = transformExtent(
    [24.888485632497463, 60.27001465422546, 24.994462951095556, 60.34508232038925],
    'EPSG:4326',
    'EPSG:3857',
)

export const atcMapFitConfig = {
    duration: 3000,
}

// position customized only for Helsinki
export const europeCentralPosition = {
    lon: 19.893307580295385,
    lat: 57.53543713442852,
    zoom: 4.3,
}

const mappingAosAirportToAirportPosition: Record<AosAirport, MapPosition> = reduce(
    typedAirports.airports,
    (acc, airport, key) => {
        acc[key] = {
            lon: airport.longitude,
            lat: airport.latitude,
            zoom: selectedLocationZoom,
        }
        return acc
    },
    {} as Record<AosAirport, MapPosition>,
)

export const getAirportPosition = (aosAirport: AosAirport): MapPosition =>
    mappingAosAirportToAirportPosition[aosAirport]
