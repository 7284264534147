// @ts-nocheck
import { bimGeojsonService } from 'aos-services/src/services/layerData/BimGeojsonService'
import { SelectedMapElementType } from 'aos-services/src/services/map/types/SelectedMapElement'
import GeoJSON from 'ol/format/GeoJSON'

import { BimLayerCategory } from './BimLayerCategory'
import { BimLayerName } from './BimLayerName'

export interface BimLayer {
    id: string | BimLayerName
    category?: BimLayerCategory
    geojson?: GeoJSON
    getGeoJson?(): Promise<GeoJSON>
    floor?: number
    name: BimLayerName
    zIndex: number
    minZoom: number
    maxZoom: number
    scale: number
    clickable?: boolean
    selectedMapElementType?: SelectedMapElementType
    originalName?: string
    isVector?: boolean
    serviceType?: 'WMS' | 'WMTS'
}
export class BimLayerDataService {
    constructor() {}
    public loadBimLayers = () =>
        Promise.all(
            bimLayerData.map(async bimLayer => ({
                ...bimLayer,
                geojson: await bimLayer.getGeoJson,
            })),
        )
}

export const bimLayerDataService = new BimLayerDataService()

export const bimLayerData: BimLayer[] = [
    {
        id: BimLayerName.Gates,
        category: BimLayerCategory.Terminal,
        name: BimLayerName.Gates,
        getGeoJson: bimGeojsonService.loadGates(),
        zIndex: 31,
        minZoom: 15,
        maxZoom: 25,
        scale: 1,
        selectedMapElementType: SelectedMapElementType.Gate,
    },
    {
        id: BimLayerName.BusDoors,
        category: BimLayerCategory.Terminal,
        name: BimLayerName.BusDoors,
        getGeoJson: bimGeojsonService.loadBusDoors(),
        zIndex: 32,
        minZoom: 15,
        maxZoom: 25,
        scale: 1,
        selectedMapElementType: SelectedMapElementType.BusDoor,
    },
    {
        id: BimLayerName.ArrivalServices,
        category: BimLayerCategory.Terminal,
        name: BimLayerName.ArrivalServices,
        getGeoJson: bimGeojsonService.loadArrivalServices(),
        zIndex: 33,
        minZoom: 15,
        maxZoom: 25,
        scale: 1,
    },
    {
        id: BimLayerName.BabyCare,
        category: BimLayerCategory.Terminal,
        name: BimLayerName.BabyCare,
        getGeoJson: bimGeojsonService.loadToiletsBabyCare(),
        zIndex: 33,
        minZoom: 15,
        maxZoom: 25,
        scale: 1,
    },
    {
        id: BimLayerName.BusGates,
        category: BimLayerCategory.Terminal,
        name: BimLayerName.BusGates,
        getGeoJson: bimGeojsonService.loadBusGates(),
        zIndex: 32,
        minZoom: 15,
        maxZoom: 25,
        scale: 1,
        selectedMapElementType: SelectedMapElementType.BusGate,
    },
    // Removed since they throw error from the service
    // {
    //     id: BimLayerName.CheckInDesks,
    //     category: BimLayerCategory.Terminal,
    //     name: BimLayerName.CheckInDesks,
    //     getGeoJson: bimGeojsonService.loadCheckInDesks(),
    //     zIndex: 33,
    //     minZoom: 15,
    //     maxZoom: 25,
    //     scale: 1,
    // },
    // {
    //     id: BimLayerName.CheckInKiosks,
    //     category: BimLayerCategory.Terminal,
    //     name: BimLayerName.CheckInKiosks,
    //     getGeoJson: bimGeojsonService.loadCheckInKiosks(),
    //     zIndex: 33,
    //     minZoom: 15,
    //     maxZoom: 25,
    //     scale: 1,
    // },
    {
        id: BimLayerName.Customs,
        category: BimLayerCategory.Terminal,
        name: BimLayerName.Customs,
        getGeoJson: bimGeojsonService.loadCustoms(),
        zIndex: 33,
        minZoom: 15,
        maxZoom: 25,
        scale: 1,
    },
    {
        id: BimLayerName.DisabledToilets,
        category: BimLayerCategory.Terminal,
        name: BimLayerName.DisabledToilets,
        getGeoJson: bimGeojsonService.loadDisabledToilets(),
        zIndex: 33,
        minZoom: 15,
        maxZoom: 25,
        scale: 1,
    },
    {
        id: BimLayerName.Lifts,
        category: BimLayerCategory.Terminal,
        name: BimLayerName.Lifts,
        getGeoJson: bimGeojsonService.loadLifts(),
        zIndex: 33,
        minZoom: 15,
        maxZoom: 25,
        scale: 1,
    },
    {
        id: BimLayerName.NonSchengenArea,
        category: BimLayerCategory.Terminal,
        name: BimLayerName.NonSchengenArea,
        getGeoJson: bimGeojsonService.loadNonSchengenArea(),
        zIndex: 21,
        minZoom: 15,
        maxZoom: 25,
        scale: 1,
    },
    {
        id: BimLayerName.SecurityChecksPassengers,
        category: BimLayerCategory.Terminal,
        name: BimLayerName.SecurityChecksPassengers,
        getGeoJson: bimGeojsonService.loadSecurityChecksPublic(),
        zIndex: 33,
        minZoom: 15,
        maxZoom: 25,
        scale: 1,
    },
    // {
    //     id: BimLayerName.SecurityCheckStaff,
    //     category: BimLayerCategory.Terminal,
    //     name: BimLayerName.SecurityCheckStaff,
    //     geojson: bimGeojsonService.loadSecurityCheckStaff(),
    //     zIndex: 12,
    //     minZoom: 15,
    //     maxZoom: 25,
    //     scale: 1,
    // },
    {
        id: BimLayerName.SisScreens,
        category: BimLayerCategory.Terminal,
        name: BimLayerName.SisScreens,
        getGeoJson: bimGeojsonService.loadSisScreens(),
        zIndex: 33,
        minZoom: 15,
        maxZoom: 25,
        scale: 1,
    },
    {
        id: BimLayerName.TerminalBuilding,
        category: BimLayerCategory.Terminal,
        name: BimLayerName.TerminalBuilding,
        getGeoJson: bimGeojsonService.loadTerminalBuilding(),
        zIndex: 16,
        minZoom: 15,
        maxZoom: 25,
        scale: 1,
    },
    {
        id: BimLayerName.Toilets,
        category: BimLayerCategory.Terminal,
        name: BimLayerName.Toilets,
        getGeoJson: bimGeojsonService.loadToilets(),
        zIndex: 33,
        minZoom: 15,
        maxZoom: 25,
        scale: 1,
    },

    // LandsideAndParking
    {
        id: BimLayerName.BusStops,
        category: BimLayerCategory.LandsideAndTraffic,
        name: BimLayerName.BusStops,
        getGeoJson: bimGeojsonService.loadBusStops(),
        zIndex: 33,
        minZoom: 15,
        maxZoom: 25,
        scale: 1,
    },
    {
        id: BimLayerName.ParkingHalls,
        category: BimLayerCategory.LandsideAndTraffic,
        name: BimLayerName.ParkingHalls,
        getGeoJson: bimGeojsonService.loadParkingHalls(),
        zIndex: 15,
        minZoom: 15,
        maxZoom: 25,
        scale: 1,
    },
    {
        id: BimLayerName.ParkingLabels,
        category: BimLayerCategory.LandsideAndTraffic,
        name: BimLayerName.ParkingLabels,
        getGeoJson: bimGeojsonService.loadParkingLabels(),
        zIndex: 16,
        minZoom: 15,
        maxZoom: 25,
        scale: 1,
    },
    {
        id: BimLayerName.ParkingPaymentMachine,
        category: BimLayerCategory.LandsideAndTraffic,
        name: BimLayerName.ParkingPaymentMachine,
        getGeoJson: bimGeojsonService.loadParkingMachines(),
        zIndex: 17,
        minZoom: 15,
        maxZoom: 25,
        scale: 1,
    },
    {
        id: BimLayerName.TaxiStands,
        category: BimLayerCategory.LandsideAndTraffic,
        name: BimLayerName.TaxiStands,
        getGeoJson: bimGeojsonService.loadTaxiStands(),
        zIndex: 22,
        minZoom: 15,
        maxZoom: 25,
        scale: 1,
    },
    {
        id: BimLayerName.TrainStation,
        category: BimLayerCategory.LandsideAndTraffic,
        name: BimLayerName.TrainStation,
        getGeoJson: bimGeojsonService.loadTrainStations(),
        zIndex: 22,
        minZoom: 15,
        maxZoom: 25,
        scale: 1,
    },
    {
        id: BimLayerName.WalkingRoutes,
        category: BimLayerCategory.LandsideAndTraffic,
        name: BimLayerName.WalkingRoutes,
        getGeoJson: bimGeojsonService.loadWalkingRoutes(),
        zIndex: 30,
        minZoom: 15,
        maxZoom: 25,
        scale: 1,
    },
    // Apron and Maneuvering
    {
        id: '8NhIk8ZtAK7gie8IAmkFL+jUTiUQ18tDU667dLJVj1o=',
        category: BimLayerCategory.ApronAndManeuvering,
        name: BimLayerName.ApronBasemap,
        zIndex: 10,
        minZoom: 15,
        maxZoom: 25,
        scale: 1,
        originalName: 'Taustakartta:asematasokartta',
    },
    {
        id: 'SycPZ1BplAwAhjVhYegxw+mWa4GLtIZFzNvKAVr39E0=',
        category: BimLayerCategory.ApronAndManeuvering,
        name: BimLayerName.AerodromeManeuvering,
        zIndex: 14,
        minZoom: 15,
        maxZoom: 25,
        scale: 1,
        originalName: 'Taustakartta:liikennealuekartta',
    },
    // {
    //     id: 'pRf0mUMmnqhgXvrlkQcwGEF6IvHd9Dw+8n8XIUMxg3E=',
    //     category: BimLayerCategory.ApronAndManeuvering,
    //     name: BimLayerName.DeIcingAreas,
    //     zIndex: 12,
    //     minZoom: 15,
    //     maxZoom: 25,
    //     scale: 1,
    //     originalName: 'Taustakartta:jaanestoalueet_remote',
    // },
    {
        id: 'oOBwsSxdKw1o7Jk8HqxsQ8DuBwsJLxuAM4hxFjPUBrw=',
        category: BimLayerCategory.ApronAndManeuvering,
        name: BimLayerName.LightMasts,
        zIndex: 12,
        minZoom: 15,
        maxZoom: 25,
        scale: 1,
        originalName: 'Taustakartta:valonheitinmastot',
    },
    {
        id: BimLayerName.Stands,
        category: BimLayerCategory.ApronAndManeuvering,
        name: BimLayerName.Stands,
        getGeoJson: bimGeojsonService.loadStands(),
        zIndex: 31,
        minZoom: 15,
        maxZoom: 25,
        scale: 1,
        selectedMapElementType: SelectedMapElementType.Stand,
    },
    {
        id: 'whBn+X5ZCED9d95JFeKzc/A677qgUXtt0FPlx3oOtDc=',
        category: BimLayerCategory.ApronAndManeuvering,
        name: BimLayerName.WaLvpScreens,
        zIndex: 14,
        minZoom: 15,
        maxZoom: 25,
        scale: 1,
        originalName: 'Taustakartta:walvp_taulut',
    },

    // AirsideArea
    {
        id: 'VLxWsm9ayOK8knAkltJkfQQrZ1npcxg302FgcySsbSE=',
        category: BimLayerCategory.AirsideArea,
        name: BimLayerName.ConstructionArea,
        zIndex: 15,
        minZoom: 15,
        maxZoom: 25,
        scale: 1,
        originalName: 'Taustakartta:tyomaa_alueet',
    },
    {
        id: 'nVmEtvKpOQUzmeph0GOXAqBX7x9oa9EFaZsNWMftfrA=',
        category: BimLayerCategory.AirsideArea,
        name: BimLayerName.SecurityFence,
        zIndex: 15,
        minZoom: 15,
        maxZoom: 25,
        scale: 1,
        originalName: 'Taustakartta:turva-aidat',
    },
    {
        id: 'ts7NKF88qytqXann4xvW/UHDzMmzLDVGbEm7jIb5aS0=',
        category: BimLayerCategory.AirsideArea,
        name: BimLayerName.SecurityFeceGatesAndCheckpoints,
        zIndex: 15,
        minZoom: 15,
        maxZoom: 25,
        scale: 1,
        originalName: 'Taustakartta:portit_tarkastuspisteet',
    },

    // Basemaps
    {
        id: 'GMrBYlPzCwYdxw/tKJ71jn1PnC8XlU+Cd3e70FSUjTE=',
        category: BimLayerCategory.Basemaps,
        name: BimLayerName.TrainRingRail,
        zIndex: 15,
        minZoom: 15,
        maxZoom: 25,
        scale: 1,
        originalName: 'Taustakartta:keharata',
    },
    {
        id: BimLayerName.Trains,
        category: BimLayerCategory.Trains,
        name: BimLayerName.Trains,
        zIndex: 15,
        minZoom: 15,
        maxZoom: 25,
        scale: 1,
        selectedMapElementType: SelectedMapElementType.Train,
    },
    {
        id: 'Y/cH5rN2qYpbYd01VsyXz7Q2S1psaOoatrdv2bYRwAM=',
        category: BimLayerCategory.Basemaps,
        name: BimLayerName.HelsinkiAirportBasemap,
        zIndex: 10,
        minZoom: 15,
        maxZoom: 25,
        scale: 1,
        originalName: 'Taustakartta:taustakartta_EFHK_uusi',
    },

    // TaskAndEvents
    {
        id: BimLayerName.Tasks,
        category: BimLayerCategory.TaskAndEvents,
        name: BimLayerName.Tasks,
        zIndex: 16,
        minZoom: 15,
        maxZoom: 25,
        scale: 1,
        selectedMapElementType: SelectedMapElementType.Task,
    },
    {
        id: BimLayerName.Events,
        category: BimLayerCategory.TaskAndEvents,
        name: BimLayerName.Events,
        zIndex: 16,
        minZoom: 15,
        maxZoom: 25,
        scale: 1,
        selectedMapElementType: SelectedMapElementType.Event,
    },

    // Floors
    {
        id: 'aR6osjg258oYTj1EgUx0b/bbgSjHsUE1SxJyVwlTScU=',
        name: BimLayerName.Floor0,
        zIndex: 12,
        minZoom: 15,
        maxZoom: 25,
        scale: 1,
        originalName: 'digimap_wms:spaces_floor0_group',
    },
    {
        id: '3iLo8PbTAi043kFs6+W0z2AWxCNLeDysWbEaoSyrWbM=',
        name: BimLayerName.Floor1,
        zIndex: 12,
        minZoom: 15,
        maxZoom: 25,
        scale: 1,
        originalName: 'digimap_wms:spaces_floor1_group',
    },
    {
        id: 'WiTDCBYt0Pv7qb4AuUVGjbJBb5WAqCBURyXyhE0otMk=',
        name: BimLayerName.Floor2,
        zIndex: 12,
        minZoom: 15,
        maxZoom: 25,
        scale: 1,
        originalName: 'digimap_wms:spaces_floor2_group',
    },
    {
        id: '25M5V9BZXAQpNU8fFoGK0cGbmuYdccJ95Izbfs1KTS4=',
        name: BimLayerName.Floor3,
        zIndex: 12,
        minZoom: 15,
        maxZoom: 25,
        scale: 1,
        originalName: 'digimap_wms:spaces_floor3_group',
    },

    // Atc
    {
        id: 'ONKI4pqWqxiPFbWMrJRUGC/BMXfgTCqSbReyO50VVEs=',
        category: BimLayerCategory.Atc,
        name: BimLayerName.NavigationGridAtc,
        zIndex: 100,
        minZoom: 10,
        maxZoom: 25,
        scale: 1,
        serviceType: 'WMS',
        originalName: 'Taustakartta:navigointiruudukko_efhk_200m_200m',
    },
    {
        id: 'EjhezundfwkagMQiU98OQLQ8OA6Zf+2VcFpvdYxPSzA=',
        category: BimLayerCategory.Atc,
        name: BimLayerName.NavigationMap,
        zIndex: 100,
        minZoom: 10,
        maxZoom: 25,
        scale: 1,
        serviceType: 'WMTS',
        originalName: 'Taustakartta:navigointiruudukko_efhk_500m_500m',
    },
    {
        id: 'uzA4cQvs78YOC8+4a6EtPCTBwCxxCdVsqnbnGn715KU=',
        category: BimLayerCategory.Atc,
        name: BimLayerName.TaxiwayLines,
        zIndex: 100,
        minZoom: 10,
        maxZoom: 25,
        serviceType: 'WMTS',
        scale: 1,
        originalName: 'Taustakartta:rullausviivat',
    },
    {
        id: 'x/28Dd/BYUPpdrO+Uqf7mhA/e/1xobrxyrtMTkTtw+E=',
        category: BimLayerCategory.Atc,
        name: BimLayerName.TaxiwayNames,
        zIndex: 100,
        minZoom: 15,
        maxZoom: 25,
        serviceType: 'WMTS',
        scale: 1,
        originalName: 'Taustakartta:turva_alueen_raja_asemataso',
    },
    {
        id: 'WF8CyX0gwSR8cfDyxrB8KxtzvwRVQkh/a4UqYuOjBnU=',
        category: BimLayerCategory.Atc,
        name: BimLayerName.RescueService,
        zIndex: 100,
        minZoom: 10,
        serviceType: 'WMTS',
        maxZoom: 25,
        scale: 1,
        originalName: 'Taustakartta:pelastuspalvelu',
    },
    {
        id: 'ntl7/C1IDwqC9T4quTq0vyojhBFh++AuZlF+YEkLIm0=',
        category: BimLayerCategory.Atc,
        name: BimLayerName.ServiceRoads,
        zIndex: 100,
        minZoom: 10,
        maxZoom: 25,
        serviceType: 'WMTS',
        scale: 1,
        originalName: 'Taustakartta:huoltotieviivat',
    },
    {
        id: '0rbxDLhhpckkEJcRE0LkBnTypAYEbhNaEFkeEL4Hv3g=',
        category: BimLayerCategory.Atc,
        name: BimLayerName.AirsideFence,
        zIndex: 100,
        minZoom: 10,
        maxZoom: 25,
        serviceType: 'WMTS',
        scale: 1,
        originalName: 'Taustakartta:lentokentta_aidat',
    },
    {
        id: 'pRf0mUMmnqhgXvrlkQcwGEF6IvHd9Dw+8n8XIUMxg3E=',
        category: BimLayerCategory.Atc,
        name: BimLayerName.DeicingArea,
        zIndex: 100,
        minZoom: 10,
        serviceType: 'WMTS',
        maxZoom: 25,
        scale: 1,
        originalName: 'Taustakartta:jaanestoalueet_remote',
    },
    {
        id: 'e0d8IQtaGnaUevzBM5YxvhLqMyu5WOXrvZDWHnQUYJU=',
        category: BimLayerCategory.Atc,
        name: BimLayerName.Buildings,
        zIndex: 100,
        minZoom: 10,
        maxZoom: 25,
        scale: 1,
        serviceType: 'WMS',
        originalName: 'Taustakartta:rakennukset_lentokentta_alue_EFHK',
    },
    {
        id: 'zuW2yw3ugDYdwfnJTNNpTgwkDxMtMtAEcQOUr7fTlIw=',
        category: BimLayerCategory.Atc,
        name: BimLayerName.TaxiwayLabels,
        zIndex: 100,
        minZoom: 10,
        serviceType: 'WMTS',
        maxZoom: 25,
        scale: 1,
        originalName: 'Taustakartta:rullaustietunnukset',
    },
    {
        id: 'biTHIePoxnF9aM5sL8yBzMkPh9pAGmstocKvxvINFRI=',
        category: BimLayerCategory.Atc,
        name: BimLayerName.RescueStationsAtc,
        zIndex: 100,
        minZoom: 10,
        maxZoom: 25,
        serviceType: 'WMTS',
        originalName: 'Taustakartta:pelastusasemat',
        scale: 1,
    },
]

export const networkLayers: BimLayer[] = [
    // {
    //     id: 'rcDIMc25ejevwwvZ9haxcPp9TBtXQFHKUxG690euO5U=',
    //     name: BimLayerName.NetworkBaseLayer,
    //     zIndex: 40,
    // },
    // TODO: Add network layers
]
