import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FCWithChildren, PropsWithChildren } from 'react'

import { Text } from '../../base/Text'

export const ModalTitle: FCWithChildren<PropsWithChildren> = ({ children }) => (
    <Text
        size={18}
        weight='light'
        color={Color.PrimaryButtonText}
        ellipsis
        textNoWrap
        flex={1}
        data-test-id='modal-container-title'
    >
        {children}
    </Text>
)
