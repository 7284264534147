import { DropdownRoot } from 'aos-ui/src/components/appcontainer/AppContainer'
import { Box } from 'aos-ui/src/components/base/Box'
import { Positioned } from 'aos-ui/src/components/base/Positioned'
import { boxShadow, ZIndex } from 'aos-ui/src/components/base/Theme'
import { DropdownRootProvider } from 'aos-ui/src/components/form/dropdown/base/DropdownRoot'
import { FullscreenButton } from 'aos-ui/src/components/fullscreen/FullscreenButton'
import { Color } from 'aos-ui-common/src/styles/Color'
import React, { FC, PropsWithChildren, useRef } from 'react'
import styled from 'styled-components'

import { ToastNavigationConnectorWrapper } from '../components/providers/ToastNavigationConnectorWrapper'
import { GlobalMessagingContainer } from './globalMessaging/GlobalMessagingContainer'
import { AppHeader } from './header/AppHeader'
import { Menu } from './menu/Menu'
import { NotificationContainer } from './notifications/NotificationContainer'

export const App: FC<PropsWithChildren> = props => {
    const ref = useRef(null)
    const rootRef = useRef(null)

    return (
        <ToastNavigationConnectorWrapper>
            <Box fullHeight column flex={1}>
                <Header shrink={0}>
                    <AppHeader />
                </Header>
                <Content flex={1} row>
                    <Menu />
                    <GlobalMessagingContainer />
                    <Dialog id='full-screen-dialog' ref={ref}>
                        <Positioned top={4} left={4} zIndex={ZIndex.HeaderButton}>
                            <FullscreenButton refEl={ref} />
                        </Positioned>
                        <DropdownRootProvider value={{ container: ref, dropdownRoot: rootRef }}>
                            {props.children}
                            <DropdownRoot ref={rootRef} />
                        </DropdownRootProvider>
                    </Dialog>
                    <NotificationContainer />
                </Content>
            </Box>
        </ToastNavigationConnectorWrapper>
    )
}

const Header = styled(Box)`
    position: relative;
    width: 100%;
    height: 96px;
    z-index: ${ZIndex.AppHeader};
    box-shadow: ${boxShadow.large};
    background-color: ${Color.WidgetBackground};
`

const Dialog = styled.article`
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    color: ${Color.White};
    background-color: ${Color.UiBlack1};
`

const Content = styled(Box)`
    position: relative;
    padding-left: 96px;
    overflow: hidden;
`
